(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/slide-selector/assets/javascripts/slide-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/slide-selector/assets/javascripts/slide-selector.js');
"use strict";

const {
  useCallback
} = React;
const {
  InputNumber
} = svs.components.input_number;
const {
  format
} = svs.utils;
const {
  analytics
} = svs.components;
const {
  useBranding
} = svs.components.tipsen.hooks;
const SlideSelector = _ref => {
  let {
    className,
    options,
    setValue,
    value,
    isValueDisabled,
    suffix
  } = _ref;
  const onValueChanged = useCallback(v => {
    const _v = options[v];
    setValue(_v);
  }, [setValue, options]);
  const slideSelectorInputsClasses = ['slide-selector-inputs'].concat(className !== null && className !== void 0 ? className : []);
  const slideSelectorInputClasses = ['slide-selector-input'];
  if (isValueDisabled && isValueDisabled(value)) {
    slideSelectorInputClasses.push('slide-selector-input-size-disabled');
  }
  const trackEventOnSlideRelease = _ref2 => {
    let {
      target
    } = _ref2;
    const selectedOption = options[target.valueAsNumber];
    analytics.trackEvent({
      category: "Nya tipsen - Fyll i med PIX!",
      action: 'Reglage',
      opt_label: location.pathname,
      opt_value: selectedOption
    });
  };
  const [productBranding] = useBranding();
  return React.createElement("div", {
    className: slideSelectorInputsClasses.join(' ')
  }, React.createElement("input", {
    className: "slide-selector-range ".concat(productBranding ? productBranding : ''),
    max: options.length - 1,
    min: 0,
    onChange: e => {
      onValueChanged(e.target.valueAsNumber);
    },
    onMouseUp: trackEventOnSlideRelease,
    onTouchEnd: trackEventOnSlideRelease,
    step: 1,
    type: "range",
    value: options.indexOf(value)
  }), React.createElement(InputNumber, {
    className: slideSelectorInputClasses.join(' '),
    focus: false,
    formatAmountFunc: format.Currency,
    onValueChange: onValueChanged,
    possibleValues: options,
    step: 1,
    suffix: suffix,
    transparentDefault: true,
    value: options.indexOf(value) !== -1 ? options.indexOf(value) : 0
  }));
};
setGlobal('svs.components.tipsen.SlideSelector', SlideSelector);

 })(window);